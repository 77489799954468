import { SVGProps } from 'react';

const WhiteShield = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={59}
		height={60}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='m35.3 20.612-.12 1.824 2.39-1.752.218-1.92M35.3 20.612l2.488-1.848M35.3 20.612a2.44 2.44 0 0 1-.801-.433m3.289-1.415 6.014-4.384m0 0 1.558-2.766-3.152.87m1.594 1.896c-.882-.605-1.223-1.018-1.594-1.896m0 0-6.028 4.499m-2.386 1.781-1.996-.205 2.548-1.884 1.834.308m-2.386 1.781 2.386-1.781m-2.386 1.781c.089.507.17.753.4 1.078m.305.337-1.09.847-.308-.342 1.094-.843m.304.338 2.809-2.231-.257-.308-2.856 2.201'
			stroke='#fff'
			strokeWidth={0.3}
		/>
		<path
			d='M29.365 46.216c6.8 0 15.5-4 19-6v-15.5m-19 21.5c-6.8 0-15.5-4-19-6v-15.5m19 21.5v-37m-19 0v-8h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#fff'
		/>
		<path
			d='M29.365 46.216c6.8 0 15.5-4 19-6v-15.5m-19 21.5c-6.8 0-15.5-4-19-6v-15.5m19 21.5v-37m-19 0v-8h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#fff'
		/>
		<path
			d='M29.365 46.216c6.8 0 15.5-4 19-6v-15.5m-19 21.5c-6.8 0-15.5-4-19-6v-15.5m19 21.5v-37m-19 0v-8h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#fff'
		/>
		<path
			d='M29.365 46.216c6.8 0 15.5-4 19-6v-15.5m-19 21.5c-6.8 0-15.5-4-19-6v-15.5m19 21.5v-37m-19 0v-8h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#fff'
		/>
		<path
			d='m10.365 5.216 15 2.5c.233-.438.366-.632.616-.885L13.282 1.554M21.446 1.552l5.407 4.61c.67-.36 1.086-.467 1.872-.561L26.97 1.55M32.143 1.575 30.13 5.577c.92.105 1.4.225 2.177.585l5.219-4.587M45.671 1.543l-12.489 5.28c.247.261.375.424.538.822l14.354-2.403'
			stroke='#fff'
			strokeWidth={0.3}
		/>
		<path
			d='M29.008 44.801a7.923 7.923 0 0 1-1.317-.691c-.549-.905-.79-1.38-1.167-2.203-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.947-1.034.005-1.752.037-2.712.161m10.1-5.799h-2.376l-.497-.324c.279-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.661.327-1.046.475-1.771.648l-.8-.453c-.346-.028-.6.054-1.101.28-.671.693-1.065 1.044-1.836 1.534a50.397 50.397 0 0 0-2.57 2.02m1.09 3.563c-.329.043-.684.096-1.09.163-.53-.322-.836-.743-1.383-1.533a2.352 2.352 0 0 1 0-.994c.517-.458.959-.843 1.382-1.2m1.09 3.564c.485-.295.637-.506.666-.976-.388-1.208-.759-1.785-1.756-2.588m-.704 2.51c-.177-.14-.188-.264-.097-.552.518.46.793.373 1.279.097m3.66-3.581c-.28-.008-.465.077-.816.414.267.227.426.328.767.359m.049-.773c.381.03.59.154.963.414-.364.259-.581.354-1.012.359m.049-.773c-.128.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.197.414-.044.175-.12.24-.246.359'
			stroke='#fff'
			strokeWidth={0.2}
		/>
		<path
			d='M29.008 44.801a7.923 7.923 0 0 1-1.317-.691c-.549-.905-.79-1.38-1.167-2.203-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.947-1.034.005-1.752.037-2.712.161m10.1-5.799h-2.376l-.497-.324c.279-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.661.327-1.046.475-1.771.648l-.8-.453c-.346-.028-.6.054-1.101.28-.671.693-1.065 1.044-1.836 1.534a50.397 50.397 0 0 0-2.57 2.02m1.09 3.563c-.329.043-.684.096-1.09.163-.53-.322-.836-.743-1.383-1.533a2.352 2.352 0 0 1 0-.994c.517-.458.959-.843 1.382-1.2m1.09 3.564c.485-.295.637-.506.666-.976-.388-1.208-.759-1.785-1.756-2.588m-.704 2.51c-.177-.14-.188-.264-.097-.552.518.46.793.373 1.279.097m3.66-3.581c-.28-.008-.465.077-.816.414.267.227.426.328.767.359m.049-.773c.381.03.59.154.963.414-.364.259-.581.354-1.012.359m.049-.773c-.128.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.197.414-.044.175-.12.24-.246.359'
			stroke='#fff'
			strokeWidth={0.2}
		/>
		<path
			d='M29.008 44.801a7.923 7.923 0 0 1-1.317-.691c-.549-.905-.79-1.38-1.167-2.203-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.947-1.034.005-1.752.037-2.712.161m10.1-5.799h-2.376l-.497-.324c.279-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.661.327-1.046.475-1.771.648l-.8-.453c-.346-.028-.6.054-1.101.28-.671.693-1.065 1.044-1.836 1.534a50.397 50.397 0 0 0-2.57 2.02m1.09 3.563c-.329.043-.684.096-1.09.163-.53-.322-.836-.743-1.383-1.533a2.352 2.352 0 0 1 0-.994c.517-.458.959-.843 1.382-1.2m1.09 3.564c.485-.295.637-.506.666-.976-.388-1.208-.759-1.785-1.756-2.588m-.704 2.51c-.177-.14-.188-.264-.097-.552.518.46.793.373 1.279.097m3.66-3.581c-.28-.008-.465.077-.816.414.267.227.426.328.767.359m.049-.773c.381.03.59.154.963.414-.364.259-.581.354-1.012.359m.049-.773c-.128.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.197.414-.044.175-.12.24-.246.359'
			stroke='#fff'
			strokeWidth={0.2}
		/>
		<path
			d='M29.008 44.801a7.923 7.923 0 0 1-1.317-.691c-.549-.905-.79-1.38-1.167-2.203-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.947-1.034.005-1.752.037-2.712.161m10.1-5.799h-2.376l-.497-.324c.279-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.661.327-1.046.475-1.771.648l-.8-.453c-.346-.028-.6.054-1.101.28-.671.693-1.065 1.044-1.836 1.534a50.397 50.397 0 0 0-2.57 2.02m1.09 3.563c-.329.043-.684.096-1.09.163-.53-.322-.836-.743-1.383-1.533a2.352 2.352 0 0 1 0-.994c.517-.458.959-.843 1.382-1.2m1.09 3.564c.485-.295.637-.506.666-.976-.388-1.208-.759-1.785-1.756-2.588m-.704 2.51c-.177-.14-.188-.264-.097-.552.518.46.793.373 1.279.097m3.66-3.581c-.28-.008-.465.077-.816.414.267.227.426.328.767.359m.049-.773c.381.03.59.154.963.414-.364.259-.581.354-1.012.359m.049-.773c-.128.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.197.414-.044.175-.12.24-.246.359'
			stroke='#fff'
			strokeWidth={0.3}
		/>
		<path d='M52.593 39.203c.05-2.557-.24-3.846-1.334-5.918' stroke='#fff' />
		<path
			d='M50.944 44.63c1.967-5.09 2.274-8.216 2.58-13.872-.413-5.54-.595-8.414-.565-13.277'
			stroke='#fff'
		/>
		<path d='M52.96 17.934c-.228-3.864-.204-6.084 0-10.162' stroke='#fff' />
		<path
			d='M25.054 58.18c2.236-2.638 4.06-3.898 8.682-5.528 5.806-1.337 8.889-2.727 11.739-4.06 3.326-1.874 4.636-3.153 6.552-5.609'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M25.054 58.18c2.236-2.638 4.06-3.898 8.682-5.528 5.806-1.337 8.889-2.727 11.739-4.06 3.326-1.874 4.636-3.153 6.552-5.609'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M25.054 58.18c2.236-2.638 4.06-3.898 8.682-5.528 5.806-1.337 8.889-2.727 11.739-4.06 3.326-1.874 4.636-3.153 6.552-5.609'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M25.054 58.18c2.236-2.638 4.06-3.898 8.682-5.528 5.806-1.337 8.889-2.727 11.739-4.06 3.326-1.874 4.636-3.153 6.552-5.609'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path d='m51.716 43.652 2.033-.669' stroke='#fff' />
		<path
			d='m56.086 41.276-.217.938.505.635-.885.478-.417.915-.668-.46-.92.28.216-.938-.505-.635.885-.478.417-.915.668.46.92-.28Z'
			fill='#fff'
		/>
		<ellipse
			cx={54.766}
			cy={42.755}
			rx={1.176}
			ry={1.437}
			transform='rotate(43.051 54.766 42.755)'
			fill='#fff'
		/>
		<path
			d='m46.901 41.586.724.635.798-.144.003 1.005.61.8-.722.371-.189.944-.724-.634-.798.144L46.6 43.7l-.61-.8.722-.37.19-.945Z'
			fill='#fff'
		/>
		<ellipse
			cx={47.58}
			cy={43.449}
			rx={1.176}
			ry={1.437}
			transform='rotate(-18.727 47.58 43.449)'
			fill='#fff'
		/>
		<path
			d='M53.443 31.387c.642-1.248 1.023-1.8 1.762-2.353'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='m50.476 30.602.6.752h.812l-.174.99.459.894-.776.239-.352.896-.6-.752h-.812l.174-.99-.459-.894.776-.239.352-.896Z'
			fill='#fff'
		/>
		<ellipse
			cx={50.817}
			cy={32.556}
			rx={1.176}
			ry={1.437}
			transform='rotate(-8.593 50.817 32.556)'
			fill='#fff'
		/>
		<path
			d='m56.546 26.273.05.961.66.472-.72.703-.148.995-.769-.259-.808.523-.05-.961-.66-.472.719-.703.149-.995.769.259.808-.523Z'
			fill='#fff'
		/>
		<ellipse
			cx={55.684}
			cy={28.058}
			rx={1.176}
			ry={1.437}
			transform='rotate(27.071 55.684 28.058)'
			fill='#fff'
		/>
		<path
			d='m49.597 17.5.848.456.745-.32.23.978.774.642-.619.524.029.962-.849-.454-.745.32-.23-.979-.773-.642.619-.524-.03-.962Z'
			fill='#fff'
		/>
		<ellipse
			cx={50.679}
			cy={19.162}
			rx={1.176}
			ry={1.437}
			transform='rotate(-31.762 50.679 19.162)'
			fill='#fff'
		/>
		<path
			d='m55.366 12.57.283.92.755.297-.527.856.098 1.001-.809-.063-.656.703-.283-.92-.755-.297.527-.857-.098-1 .808.063.657-.704Z'
			fill='#fff'
		/>
		<ellipse
			cx={54.965}
			cy={14.511}
			rx={1.176}
			ry={1.437}
			transform='rotate(12.988 54.965 14.51)'
			fill='#fff'
		/>
		<path
			d='m54.443 4.969-.128.954.56.585-.836.558-.33.95-.708-.396-.89.365.128-.954-.561-.586.836-.558.33-.95.708.396.891-.364Z'
			fill='#fff'
		/>
		<ellipse
			cx={53.267}
			cy={6.564}
			rx={1.176}
			ry={1.437}
			transform='rotate(37.721 53.267 6.564)'
			fill='#fff'
		/>
		<path
			d='M53.041 18.407c.274-1.516.553-2.133 1.308-2.75M53.221 24.18c-.565-1.93-.978-2.887-1.848-4.42M45.027 48.686c1.467-1.63 1.992-2.577 2.303-4.33'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M26.652 58.533c-1.026.153-1.561.01-2.341-1.267 2.053-1.857 3.258-2.554 5.454-3.5l.967.838c-1.91 1.423-2.902 2.25-4.08 3.93Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M26.652 58.533c-1.026.153-1.561.01-2.341-1.267 2.053-1.857 3.258-2.554 5.454-3.5l.967.838c-1.91 1.423-2.902 2.25-4.08 3.93Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M26.652 58.533c-1.026.153-1.561.01-2.341-1.267 2.053-1.857 3.258-2.554 5.454-3.5l.967.838c-1.91 1.423-2.902 2.25-4.08 3.93Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M26.652 58.533c-1.026.153-1.561.01-2.341-1.267 2.053-1.857 3.258-2.554 5.454-3.5l.967.838c-1.91 1.423-2.902 2.25-4.08 3.93Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path d='M6.04 39.404c-.049-2.556.24-3.845 1.334-5.918' stroke='#fff' />
		<path
			d='M7.69 44.831c-1.968-5.09-2.275-8.215-2.581-13.872.413-5.539.596-8.414.565-13.276'
			stroke='#fff'
		/>
		<path d='M5.673 18.135c.228-3.864.205-6.084 0-10.162' stroke='#fff' />
		<path
			d='M33.58 58.381c-2.237-2.638-4.06-3.897-8.683-5.527-5.805-1.337-8.888-2.727-11.738-4.06-3.326-1.875-4.637-3.154-6.553-5.61'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M33.58 58.381c-2.237-2.638-4.06-3.897-8.683-5.527-5.805-1.337-8.888-2.727-11.738-4.06-3.326-1.875-4.637-3.154-6.553-5.61'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M33.58 58.381c-2.237-2.638-4.06-3.897-8.683-5.527-5.805-1.337-8.888-2.727-11.738-4.06-3.326-1.875-4.637-3.154-6.553-5.61'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path
			d='M33.58 58.381c-2.237-2.638-4.06-3.897-8.683-5.527-5.805-1.337-8.888-2.727-11.738-4.06-3.326-1.875-4.637-3.154-6.553-5.61'
			stroke='#fff'
			strokeWidth={2}
		/>
		<path d='m6.918 43.853-2.034-.669' stroke='#fff' />
		<path
			d='m2.548 41.477.216.938-.504.635.885.478.416.916.668-.46.921.28-.216-.939.504-.635-.885-.478-.416-.915-.668.46-.921-.28Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(43.051 -56.388 16.576)'
			fill='#fff'
		/>
		<path
			d='m11.732 41.788-.724.634-.798-.144-.003 1.006-.609.8.721.37.19.945.723-.635.799.144.002-1.005.61-.8-.722-.372-.189-.943Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-18.727 126.832 55.34)'
			fill='#fff'
		/>
		<path
			d='M5.19 31.589c-.641-1.25-1.023-1.8-1.761-2.354'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='m8.158 30.804-.601.752-.811-.002.174.99-.46.896.776.238.352.896.601-.752.811.001-.174-.99.46-.895-.776-.238-.352-.896Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-8.593 214.096 68.401)'
			fill='#fff'
		/>
		<path
			d='m2.088 26.474-.05.962-.66.471.719.703.148.995.77-.258.808.522.05-.96.66-.473-.72-.703-.148-.994-.77.258-.807-.523Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(27.071 -60.17 8.005)'
			fill='#fff'
		/>
		<path
			d='m9.037 17.702-.849.455-.745-.32-.23.979-.774.641.62.525-.03.962.849-.455.745.32.23-.979.774-.641-.62-.525.03-.962Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-31.762 30.054 23.662)'
			fill='#fff'
		/>
		<path
			d='m3.267 12.77-.282.92-.755.298.526.857-.098 1 .809-.063.657.704.282-.92.755-.298-.526-.857.098-1-.81.063-.656-.704Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(12.988 -66.456 -8.757)'
			fill='#fff'
		/>
		<path
			d='m4.19 5.17.129.954-.561.586.836.558.33.95.708-.396.89.364-.128-.954.562-.586-.837-.558-.33-.95-.708.396-.89-.364Z'
			fill='#fff'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(37.721 -12.586 -4.473)'
			fill='#fff'
		/>
		<path
			d='M5.592 18.609c-.273-1.517-.553-2.134-1.307-2.75M5.412 24.38c.565-1.929.978-2.886 1.849-4.418M13.606 48.888c-1.467-1.631-1.991-2.577-2.303-4.33'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M31.982 58.735c1.025.152 1.56.01 2.34-1.267-2.052-1.858-3.258-2.555-5.454-3.5l-.966.837c1.91 1.424 2.902 2.25 4.08 3.93Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.5}
		/>
		<path
			d='M29.363 24.73h-.1v.1l.08 21.27v.109l.11-.01c7.069-.644 11.297-1.97 18.867-5.928l.054-.028V24.73h-19.01Zm2.883 10.816c-.266-.927-.385-1.658-.342-2.395.043-.736.249-1.484.643-2.444l1.129.734.092.06.051-.098c.479-.93.87-1.379 1.77-2.087l.084-.066-.07-.08-.94-1.053c.894-.622 1.63-.981 2.415-1.147.788-.166 1.634-.138 2.754.03l-.3 1.402-.02.093.092.024c1.04.276 1.54.514 2.333 1.076l.084.06.057-.086.783-1.166c.736.632 1.223 1.178 1.572 1.796.35.62.564 1.317.747 2.255l-1.37.171-.099.013.013.1c.104.8.1 1.246 0 2.048l-.01.077.073.029 1.262.487c-.313.898-.615 1.557-1.027 2.126-.41.564-.93 1.046-1.684 1.585l-.654-1.235-.052-.098-.092.061c-.441.294-.797.485-1.176.614-.38.13-.79.199-1.342.245l-.092.008V40.24c-.977-.038-1.718-.137-2.408-.361-.69-.225-1.335-.576-2.116-1.127l.805-1.178.05-.074-.068-.059c-.428-.365-.728-.667-.976-1.004-.249-.337-.448-.714-.672-1.233l-.036-.084-.087.029-1.176.397Zm4.41-2.055c0 .886.68 2.015 1.968 2.015 1.06 0 1.921-.968 1.921-2.015 0-1.047-.78-1.968-1.921-1.968-1.248 0-1.968 1.018-1.968 1.968Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.2}
		/>
		<path
			d='M10.43 9.218h-.1v.1l-.053 15.368v.1H29.405V9.218H10.43Zm7.62 6.776-.062-.078-.078.06-2.44 1.853-.867-1.223 2.292-1.878.076-.063-.062-.077-1.835-2.299 1.275-1.103 1.904 2.44.062.079.078-.062 2.341-1.825.98 1.158-2.466 2.001-.076.062.061.078 4.803 6.053-1.227.982-4.76-6.158Z'
			fill='#fff'
			stroke='#fff'
			strokeWidth={0.2}
		/>
	</svg>
);

export default WhiteShield;
