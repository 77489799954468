// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets//img//foto-muni-noche.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._hero__heroContainer__2qKEr {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n\twidth: 100%;\r\n\theight: 50vh;\r\n}\r\n\r\n._hero__imgContainer__E6qLq {\r\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n\tbackground-repeat: no-repeat;\r\n\tbackground-position: center;\r\n\tbackground-size: cover;\r\n\theight: 50vh;\r\n\twidth: 100%;\r\n}\r\n\r\n._hero__line__CzePc {\r\n\theight: 0.5em;\r\n\twidth: 100%;\r\n\tbackground: rgb(240, 35, 137);\r\n\tbackground: linear-gradient(\r\n\t\t90deg,\r\n\t\trgba(240, 35, 137, 1) 0%,\r\n\t\trgba(5, 157, 214, 1) 25%,\r\n\t\trgba(240, 35, 137, 1) 50%,\r\n\t\trgba(5, 157, 214, 1) 75%,\r\n\t\trgba(240, 35, 137, 1) 100%\r\n\t);\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/pages/Home/components/Hero/_hero.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,8BAA8B;CAC9B,WAAW;CACX,YAAY;AACb;;AAEA;CACC,yDAAqE;CACrE,4BAA4B;CAC5B,2BAA2B;CAC3B,sBAAsB;CACtB,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,WAAW;CACX,6BAA6B;CAC7B;;;;;;;EAOC;AACF","sourcesContent":[".heroContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n\twidth: 100%;\r\n\theight: 50vh;\r\n}\r\n\r\n.imgContainer {\r\n\tbackground-image: url('../../../../assets//img//foto-muni-noche.jpg');\r\n\tbackground-repeat: no-repeat;\r\n\tbackground-position: center;\r\n\tbackground-size: cover;\r\n\theight: 50vh;\r\n\twidth: 100%;\r\n}\r\n\r\n.line {\r\n\theight: 0.5em;\r\n\twidth: 100%;\r\n\tbackground: rgb(240, 35, 137);\r\n\tbackground: linear-gradient(\r\n\t\t90deg,\r\n\t\trgba(240, 35, 137, 1) 0%,\r\n\t\trgba(5, 157, 214, 1) 25%,\r\n\t\trgba(240, 35, 137, 1) 50%,\r\n\t\trgba(5, 157, 214, 1) 75%,\r\n\t\trgba(240, 35, 137, 1) 100%\r\n\t);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroContainer": "_hero__heroContainer__2qKEr",
	"imgContainer": "_hero__imgContainer__E6qLq",
	"line": "_hero__line__CzePc"
};
export default ___CSS_LOADER_EXPORT___;
