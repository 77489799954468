import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} {...props}>
		<path
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#a3185d',
				fillOpacity: 1,
			}}
			d='M25.027 7.777c6.672 0 7.196.524 7.196 7.196v10.054c0 6.672-.524 7.196-7.196 7.196H23.75v-8.899h3.137l.496-3.527H23.75s-.004-2.063 0-2.578c.012-1.008.852-1.52 1.574-1.512.727.008 2.223.004 2.223.004v-3.25s-1.297-.168-2.656-.184c-1.145-.011-2.407.297-3.426 1.325-1.035 1.046-1.2 2.601-1.219 4.5-.008.55 0 1.695 0 1.695h-3.07v3.527h3.07v8.899h-5.273c-6.672 0-7.196-.524-7.196-7.196V14.973c0-6.672.524-7.196 7.196-7.196Zm0 0'
		/>
	</svg>
);

export default SvgComponent;
