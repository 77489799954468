// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._adminDashboard__container__fROeN {\r\n\tpadding: 30px 0;\r\n\tmin-height: calc(100vh - 148px - 80px - 30px);\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tmargin: 0px auto;\r\n\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 1em;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/_adminDashboard.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,6CAA6C;CAC7C,WAAW;CACX,iBAAiB;CACjB,gBAAgB;;CAEhB,aAAa;CACb,sBAAsB;CACtB,QAAQ;AACT","sourcesContent":[".container {\r\n\tpadding: 30px 0;\r\n\tmin-height: calc(100vh - 148px - 80px - 30px);\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tmargin: 0px auto;\r\n\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_adminDashboard__container__fROeN"
};
export default ___CSS_LOADER_EXPORT___;
