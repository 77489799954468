// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._tourismSection__container__EAL5M {\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tpadding: 0 1em;\r\n\tmargin: auto;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\r\n\tmargin-top: 30px;\r\n}\r\n\r\n._tourismSection__main__KooOD {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tflex-wrap: wrap;\r\n\tgap: 1.5em;\r\n\r\n\tmargin: 1.5em 0;\r\n}\r\n\r\n@media screen and (max-width: 800px) {\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/pages/TourismSection/_tourismSection.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,iBAAiB;CACjB,cAAc;CACd,YAAY;CACZ,aAAa;CACb,sBAAsB;;CAEtB,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,eAAe;CACf,UAAU;;CAEV,eAAe;AAChB;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".container {\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tpadding: 0 1em;\r\n\tmargin: auto;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\r\n\tmargin-top: 30px;\r\n}\r\n\r\n.main {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tflex-wrap: wrap;\r\n\tgap: 1.5em;\r\n\r\n\tmargin: 1.5em 0;\r\n}\r\n\r\n@media screen and (max-width: 800px) {\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_tourismSection__container__EAL5M",
	"main": "_tourismSection__main__KooOD"
};
export default ___CSS_LOADER_EXPORT___;
