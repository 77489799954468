// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._formSelect__select__IRdzX {\r\n\tmargin-bottom: 20px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n}\r\n\r\n._formSelect__select__IRdzX label {\r\n\tdisplay: block;\r\n\tmargin-bottom: 10px;\r\n\tfont-size: 14px;\r\n\tfont-family: var(--font-family);\r\n}\r\n\r\n._formSelect__select__IRdzX select {\r\n\theight: 40px;\r\n\tpadding: 0 10px;\r\n\tbackground-color: var(--grayOne);\r\n\tborder-bottom: 2px solid var(--darkPink);\r\n\tborder-top: 0;\r\n\tborder-left: 0;\r\n\tborder-right: 0;\r\n}\r\n\r\n._formSelect__select__IRdzX select option {\r\n\theight: 40px;\r\n\tpadding: 0 10px;\r\n\tbackground-color: white;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/FormSelect/_formSelect.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,cAAc;CACd,mBAAmB;CACnB,eAAe;CACf,+BAA+B;AAChC;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,gCAAgC;CAChC,wCAAwC;CACxC,aAAa;CACb,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,uBAAuB;AACxB","sourcesContent":[".select {\r\n\tmargin-bottom: 20px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n}\r\n\r\n.select label {\r\n\tdisplay: block;\r\n\tmargin-bottom: 10px;\r\n\tfont-size: 14px;\r\n\tfont-family: var(--font-family);\r\n}\r\n\r\n.select select {\r\n\theight: 40px;\r\n\tpadding: 0 10px;\r\n\tbackground-color: var(--grayOne);\r\n\tborder-bottom: 2px solid var(--darkPink);\r\n\tborder-top: 0;\r\n\tborder-left: 0;\r\n\tborder-right: 0;\r\n}\r\n\r\n.select select option {\r\n\theight: 40px;\r\n\tpadding: 0 10px;\r\n\tbackground-color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "_formSelect__select__IRdzX"
};
export default ___CSS_LOADER_EXPORT___;
