// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pHTO6eIj53eG2H60GB6a{width:100%;max-width:1024px;min-height:calc(100vh - 80px - 148px - 30px);margin:0 auto;padding:2em 0}", "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/pages/News/TourismForm/_tourismForm.module.scss"],"names":[],"mappings":"AAAA,sBACC,UAAA,CACA,gBAAA,CACA,4CAAA,CACA,aAAA,CACA,aAAA","sourcesContent":[".page {\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tmin-height: calc(100vh - 80px - 148px - 30px);\r\n\tmargin: 0 auto;\r\n\tpadding: 2em 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "pHTO6eIj53eG2H60GB6a"
};
export default ___CSS_LOADER_EXPORT___;
