// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._title__title__IycnI {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 20px;\r\n\tfont-weight: 700;\r\n\tcolor: var(--primaryBlue);\r\n\ttext-transform: uppercase;\r\n\ttext-align: center;\r\n\tmargin-top: 30px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Titlte/_title.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;CAChB,yBAAyB;CACzB,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;AACjB","sourcesContent":[".title {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 20px;\r\n\tfont-weight: 700;\r\n\tcolor: var(--primaryBlue);\r\n\ttext-transform: uppercase;\r\n\ttext-align: center;\r\n\tmargin-top: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_title__title__IycnI"
};
export default ___CSS_LOADER_EXPORT___;
