// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yS7LCrNyTM7JBcBUVJPv{font-size:1em}.yS7LCrNyTM7JBcBUVJPv.Lf3Esr7p4kCjWsNXghx1{font-size:.75em}.yS7LCrNyTM7JBcBUVJPv._WZrobWP1IV4w8j4955N{font-size:1.25em}.yS7LCrNyTM7JBcBUVJPv.fgWUVby8KziZ1OA_o_uO{font-size:1.5em}", "",{"version":3,"sources":["webpack://./src/components/icon/Icon.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CAEA,2CACE,eAAA,CAGF,2CACE,gBAAA,CAGF,2CACE,eAAA","sourcesContent":[".icon {\r\n  font-size: 1em;\r\n\r\n  &.icon__size__small {\r\n    font-size: 0.75em;\r\n  }\r\n\r\n  &.icon__size__large {\r\n    font-size: 1.25em;\r\n  }\r\n\r\n  &.icon__size__xlarge {\r\n    font-size: 1.5em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "yS7LCrNyTM7JBcBUVJPv",
	"icon__size__small": "Lf3Esr7p4kCjWsNXghx1",
	"icon__size__large": "_WZrobWP1IV4w8j4955N",
	"icon__size__xlarge": "fgWUVby8KziZ1OA_o_uO"
};
export default ___CSS_LOADER_EXPORT___;
