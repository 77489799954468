import './App.css';
import Footer from './components/Footer/Footer';
import { HashRouter, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { AuthGuard } from './guards';
import { PublicRoutes, PrivateRoutes } from './models';
import { RoutesWithNotFound } from './utilities';
import { lazy, Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import NewsSection from './pages/NewsSection/NewsSection';
import GobermentInfo from './pages/GobermentInfo/GobermentInfo';
import AuthoritiesInfo from './pages/AuthoritiesInfo/AuthoritiesInfo';
import NoticePage from './pages/NoticePage/NoticePage';
import TourismSection from './pages/TourismSection/TourismSection';
import TourismPage from './pages/TourismPage/TourismPage';
import { Header } from './components/header/Header';
import CommercialAuth from './pages/CommercialAuth/CommercialAuth';
import DriversLicenses from './pages/DriversLicenses/DriversLicenses';
import ResiduesRecolection from './pages/ResiduesRecolection/ResiduesRecolection';
import Rents from './pages/Rents/Rents';
import CustomTheme from './theme/themeConfig';

const Home = lazy(async () => await import('./pages/Home/Home'));
const Private = lazy(async () => await import('./pages/Private/Private'));

const App = () => {
	return (
		<Suspense fallback={<>Cargando</>}>
			<ThemeProvider theme={CustomTheme}>
				<HashRouter>
					<Header />
					<RoutesWithNotFound>
						<Route path='/' element={<Home />} />
						<Route path={PublicRoutes.LOGIN} element={<Login />} />
						<Route path={PublicRoutes.REGISTER} element={<Register />} />
						<Route path={PublicRoutes.NEWS} element={<NewsSection />} />
						<Route path={`${PublicRoutes.NEWS}/:id`} element={<NoticePage />} />
						<Route path={PublicRoutes.TOURISM} element={<TourismSection />} />
						<Route
							path={`${PublicRoutes.TOURISM}/:id`}
							element={<TourismPage />}
						/>
						<Route path={PublicRoutes.GOBERMENT} element={<GobermentInfo />} />
						<Route
							path={PublicRoutes.AUTHORITIES}
							element={<AuthoritiesInfo />}
						/>
						<Route
							path={PublicRoutes.COMMERCIAL_AUTH}
							element={<CommercialAuth />}
						/>
						<Route
							path={PublicRoutes.DRIVERS_LICENSES}
							element={<DriversLicenses />}
						/>
						<Route
							path={PublicRoutes.RESIDUE_RECOLECTION}
							element={<ResiduesRecolection />}
						/>
						<Route path={PublicRoutes.RENTS} element={<Rents />} />
						<Route element={<AuthGuard />}>
							<Route
								path={`${PrivateRoutes.PRIVATE}/*`}
								element={<Private />}
							/>
						</Route>
					</RoutesWithNotFound>
					<div style={{ height: '30px' }} />
					<Footer />
				</HashRouter>
			</ThemeProvider>
		</Suspense>
	);
};

export default App;
